import styled from 'styled-components';

const Textarea = styled.textarea`

    padding-bottom: 25px;
    resize: vertical;
    height: 100px;

`

export default Textarea;