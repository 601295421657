

export const COLORS = {

    Blue: '#2474BF',
    LightBlue: '#4F8FCB',
    Yellow: '#F4EC9D',
    Gray: '#F3F5F6',
    White: '#FFFFFF',
    Black: '#222222',
    HeartRed: '#F24E1E',
    Red: '#E30909',
    LightRed: '#d65454',
    DarkGray: '#8e9091',
    LightBlueHover: '#95bfe8',
    DarkBlue: '#2169ad',

}
