import styled from 'styled-components'
import { COLORS } from '../../Share/Colors'

const Abbr = styled.abbr`

    text-decoration: none;
    color: ${COLORS.Red};

`

export default Abbr